import React from 'react';
import PropTypes from 'prop-types';

import BoldTitle from './BoldTitle';
import MobileAppLinks from './MobileAppLinks';
import DesktopOrWebAppLinks from './DesktopOrWebAppLinks';

function AllDownloadLinks({
  title,
  downloadLinks,
  desktopDownloadPreviewImage,
  desktopDownloadPreviewImageFile,
}) {
  const mobileLinks = {
    playStore: downloadLinks?.playStore,
    appStore: downloadLinks?.appStore,
    huawei: downloadLinks?.huawei,
  };

  const desktopLinks = {
    windows: downloadLinks?.windows,
    mac: downloadLinks?.mac,
    linux: downloadLinks?.linux,
  };
  const webLinks = {
    web: downloadLinks?.web,
  };

  return (
    <div className="flex justify-center w-full my-12 md:my-24" id="all-links">
      <div className="w-full max-w-6xl px-6 py-2 xl:px-0">
        <BoldTitle>{title}</BoldTitle>
        <div className="grid grid-cols-1 gap-6 mt-10 lg:gap-8 lg:grid-cols-3">
          <div className="w-full">
            <MobileAppLinks mobileLinks={mobileLinks} />
          </div>
          <div className="lg:col-span-2">
            <DesktopOrWebAppLinks
              image={desktopDownloadPreviewImage}
              imageFile={desktopDownloadPreviewImageFile}
              desktopLinks={desktopLinks}
              webLinks={webLinks}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AllDownloadLinks.propTypes = {
  title: PropTypes.string.isRequired,
  downloadLinks: PropTypes.object.isRequired,
  desktopDownloadPreviewImage: PropTypes.string.isRequired,
  desktopDownloadPreviewImageFile: PropTypes.object.isRequired,
};

export default AllDownloadLinks;
