import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { extractFileName } from '../utils';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { faArrowDown, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Image } from './image';
import { Modal } from './modal';

const AppTitleFullWidth = ({ appData, className }) => {
  const [play, setPlay] = useState(false);
  const url = play
    ? `${appData.videoEmbedUrl}&autoplay=1`
    : appData.videoEmbedUrl;
  return (
    <div className={` w-full bg-neutral ${className}`}>
      <div className="w-full max-w-6xl px-6 m-auto">
        <div className="pb-4 text-center md:col-span-1 md:text-left pt-14 md:pt-16 ">
          <div className="w-full text-center">
            <h1
              className={`${
                appData.font == 'bangla'
                  ? 'font-bangla-semiBold'
                  : 'font-semiBold'
              } font-semiBold text-4xl md:text-6xl`}
            >
              {appData.appName}
            </h1>
            <p className="my-2 text-2xl md:text-3xl">
              {appData.description.replace(/<br\s*\/?>/gi, ' ')}
            </p>
            <div className="m-auto mt-6 w-max">
              <AnchorLink to="#all-links" stripHash>
                <div className="px-4 py-2 text-xs transition-colors rounded-lg hover:bg-neutral-8 hover:shadow-md w-max bg-neutral-9 text-neutral md:text-base">
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    alt="arrow icon"
                    className="mr-2"
                  />
                  Download for Your Device
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
      <div>
        {play && appData.videoEmbedUrl && (
          <Modal
            isOpen={play}
            onClose={() => setPlay(false)}
            className="max-w-3xl"
          >
            <iframe
              className="w-full border-0 rounded-lg aspect-video"
              src={url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen="allowfullscreen"
            />
          </Modal>
        )}
        <div className={`relative h-full w-full`}>
          {appData.videoEmbedUrl && (
            <div className="absolute inset-0 z-10 flex items-center justify-center">
              <div className="relative flex items-center justify-center w-max h-max">
                <div className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-primary-3" />
                <button
                  className="z-10 w-16 h-16 rounded-full shadow-xl lg:w-20 lg:h-20 bg-primary-2"
                  onClick={() => setPlay(!play)}
                >
                  <FontAwesomeIcon
                    icon={faPlay}
                    size="2x"
                    className="text-neutral-7"
                  />
                </button>
              </div>
            </div>
          )}

          <Image
            alt={extractFileName(appData.heroImage)}
            imageFile={appData.imageFile}
            className="m-auto"
          />
        </div>
      </div>
    </div>
  );
};

AppTitleFullWidth.propTypes = {
  appData: PropTypes.object,
  className: PropTypes.string,
};

AppTitleFullWidth.defaultProps = {
  className: '',
};

export default AppTitleFullWidth;
