import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';
import { Image } from './image';

const query = graphql`
  {
    allAppList(limit: 4) {
      nodes {
        appName
        route
        smallImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

function OtherApps({ currentAppName }) {
  const data = useStaticQuery(query);
  const appList = data.allAppList.nodes.filter(
    (item) => item.appName !== currentAppName,
  );

  if (appList.length === 4) {
    appList.pop();
  }

  return (
    <div className="bg-neutral md:flex md:justify-center">
      <div className="max-w-6xl my-12 md:my-24 p-6 lg:p-0">
        <BoldTitle>Check Out Our Other Apps</BoldTitle>
        <div className="md:grid md:grid-cols-3 mt-10 md:gap-6 lg:gap-11">
          {appList.map((item) => (
            <Link to={`/apps/${item.route}`} key={item.route}>
              <div className="md:max-w-80 rounded-2xl hover:shadow-2xl ease-in-out transition duration-500 m-6 md:m-0">
                <div className="bg-primary-0">
                  <Image imageFile={item.smallImageFile} className="m-auto" />
                </div>
                <div className="font-semiBold border-2 rounded-bl-2xl rounded-br-2xl border-primary-0 p-6">
                  {item.appName}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

OtherApps.propTypes = {
  currentAppName: PropTypes.string.isRequired,
};

export default OtherApps;
