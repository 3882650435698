import React from 'react';
import PropTypes from 'prop-types';

import starIcon from '../icons/star-gold.svg';

function Reviews({ reviews }) {
  const getAvatar = (reviewItem) => {
    return (
      <div className="flex items-center justify-center w-8 h-8 mr-4 rounded-full bg-primary font-regular text-neutral">
        {reviewItem.avatar ? (
          <img
            src={reviewItem.avatar}
            className="w-8 h-8"
            alt={`profile avatar ${reviewItem.reviewer[0]}`}
          />
        ) : (
          reviewItem.reviewer[0].toUpperCase()
        )}
      </div>
    );
  };

  const getReviewerAndRating = (reviewItem) => (
    <div>
      <div className="text-sm font-semiBold">{reviewItem.reviewer}</div>
      <div className="flex text-xs">
        {[...Array(reviewItem.stars).keys(reviewItem.stars)].map((item) => (
          <img key={item} src={starIcon} />
        ))}
      </div>
    </div>
  );

  const reviewCard = (reviewItem) => (
    <div className="max-h-full p-6 overflow-hidden shadow-2xl rounded-2xl overflow-ellipsis">
      <div className="flex">
        {getAvatar(reviewItem)}
        {getReviewerAndRating(reviewItem)}
      </div>
      <div className="mt-4 text-sm font-regular">{reviewItem.reviewText}</div>
    </div>
  );

  const renderTitle = () => (
    <div className="px-6 text-3xl text-center font-extraBold md:text-5xl">
      What Our <span className="text-primary">Users</span> Had To Say?
    </div>
  );

  reviews.sort((a, b) => b.reviewText.length - a.reviewText.length);

  return (
    /** The reason for hard coding indexes is to ensure the largest
     * reviews are place at the top and the bottom
     * and the middle two are the smallest ones.
     */
    <div className="bg-neutral">
      <div className="max-w-6xl px-6 py-12 mx-auto lg:py-24 xl:px-0">
        <div className="block lg:hidden">{renderTitle()}</div>
        <div className="lg:h-44 lg:flex lg:justify-center lg:mb-14">
          <div className="relative py-6 lg:w-2/5 lg:left-14 mx-9 lg:mx-0 md:p-6 lg:p-0">
            {reviews[0] && reviewCard(reviews[0])}
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-4 lg:gap-10">
          <div className="relative py-6 lg:bottom-16 mx-9 lg:mx-0 md:p-6 lg:p-0">
            {reviews[3] && reviewCard(reviews[3])}
          </div>
          <div className="hidden lg:col-span-2 lg:block">{renderTitle()}</div>
          <div className="py-6 mx-9 lg:mx-0 md:p-6 lg:p-0">
            {reviews[4] && reviewCard(reviews[4])}
          </div>
        </div>
        <div className="lg:h-44 lg:flex lg:mt-10 lg:pl-20">
          <div className="relative py-6 lg:w-96 lg:h-60 lg:bottom-16 mx-9 lg:mx-0 md:p-6 lg:p-0">
            {reviews[1] && reviewCard(reviews[1])}
          </div>
          <div className="relative py-6 lg:w-2/5 lg:left-40 mx-9 lg:mx-0 md:p-6 lg:p-0">
            {reviews[2] && reviewCard(reviews[2])}
          </div>
        </div>
      </div>
    </div>
  );
}

Reviews.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default Reviews;
