import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileButton } from '@fortawesome/free-solid-svg-icons';

import { appStoreNames } from '../constants';
import FeaturedStoreLinkButton from './FeaturedStoreLinkButton';

const MobileAppLinks = ({ mobileLinks }) => {
  return (
    <div className="h-full p-4 shadow-xl md:p-6 bg-neutral md:rounded-3xl rounded-2xl">
      <div className="p-3 rounded-lg md:p-4 md:rounded-2xl bg-secondary-1 w-max">
        <FontAwesomeIcon
          icon={faMobileButton}
          className="mx-1 text-2xl text-secondary"
        />
      </div>
      <p className="mt-2 font-bold md:text-3xl text-neutral-7">Mobile App</p>
      <div className="grid max-w-lg grid-cols-2 gap-2 mt-4 md:gap-4 md:mt-10">
        {appStoreNames.map(
          (item) =>
            mobileLinks[item.slug] && (
              <div key={item.slug}>
                <FeaturedStoreLinkButton
                  className="w-full"
                  icon={item.icon}
                  link={{
                    label: item.secondaryLabel,
                    url: mobileLinks[item.slug],
                  }}
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
};

MobileAppLinks.propTypes = {
  mobileLinks: PropTypes.object.isRequired,
};

export default MobileAppLinks;
