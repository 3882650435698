import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function FeaturedStoreLinkButton({ link, title, className, icon }) {
  return (
    <a href={link.url} target="_blank" rel="noreferrer">
      <button
        className={clsx(
          className,
          'bg-neutral-9 py-2 px-2 md:px-4 rounded-lg hover:shadow-md hover:bg-neutral-8 transition-colors   text-neutral font-regular text-sm',
        )}
      >
        <div className="flex items-center justify-start">
          <div className="w-6 mr-2">
            {icon && <img className="w-full" src={icon} alt="Logo" />}
          </div>
          <div className="text-xs text-start md:text-sm">
            {title || ' Get it on'}
            <p className="font-bold"> {link.label}</p>
          </div>
        </div>
      </button>
    </a>
  );
}

FeaturedStoreLinkButton.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  link: PropTypes.object.isRequired,
  className: PropTypes.string,
};

FeaturedStoreLinkButton.defaultProps = {
  className: '',
};

export default FeaturedStoreLinkButton;
