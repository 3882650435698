import React from 'react';
import PropTypes from 'prop-types';

import BoldTitle from './BoldTitle';
import { Image } from './image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatStringWithDoubleLineBreaks } from '../utils';

function AppFeatures({ index, imageFile, features, imageAlt }) {
  const getImageBlock = () => (
    <div className="mt-10 lg:mt-0 lg:col-span-2">
      <Image alt={imageAlt} imageFile={imageFile} className="mx-auto lg:mx-0" />
    </div>
  );

  const getFeatureDescription = (featureItem) => (
    <div className="mx-auto mt-6 lg:mr-16 lg:col-span-1 lg:mx-0 max-w-52 lg:w-auto">
      <div className="flex w-12 h-12 mx-auto rounded-full bg-primary-1 lg:mx-0">
        <FontAwesomeIcon icon={featureItem.icon} className="m-auto" />
      </div>
      <div className="mt-4 mb-2 font-semiBold">{featureItem.title}</div>
      <div className="text-sm max-w-52 font-regular overflow-ellipsis">
        {formatStringWithDoubleLineBreaks(featureItem.description)}
      </div>
    </div>
  );

  const getFeatureBlock = () => (
    <div className="text-center lg:col-span-2 lg:text-left">
      <BoldTitle>Here&apos;s What You Get</BoldTitle>

      <div className="lg:grid lg:grid-cols-2">
        {features[0] && getFeatureDescription(features[0])}
        {features[1] && getFeatureDescription(features[1])}
      </div>
      <div className="lg:grid lg:grid-cols-2">
        {features[2] && getFeatureDescription(features[2])}
        {features[3] && getFeatureDescription(features[3])}
      </div>
    </div>
  );

  return (
    <div
      className={`p-6 md:p-12 lg:p-24 flex justify-center items-center w-full ${
        index % 2 === 0 ? 'bg-neutral' : 'bg-neutral-1'
      }`}
    >
      <div className="w-full max-w-6xl lg:grid lg:grid-cols-4">
        {index % 2 == 0 ? (
          <React.Fragment>
            <div className="hidden lg:block lg:col-span-2">
              {getImageBlock()}
            </div>
            {getFeatureBlock()}
            <div className="block lg:hidden lg:col-span-2">
              {getImageBlock()}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getFeatureBlock()}
            {getImageBlock()}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

AppFeatures.propTypes = {
  imageAlt: PropTypes.string,
  imageFile: PropTypes.object,
  index: PropTypes.number,
  features: PropTypes.array.isRequired,
};

export default AppFeatures;
